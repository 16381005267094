import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button,Container } from "react-bootstrap";
import { useUserAuth } from "../context/UserAuthContext";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from '../firebase';


const Signup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const { signUp } = useUserAuth();
  let navigate = useNavigate();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await signUp(email, password);
      

      const user = userCredential.user;
      const uid = user.uid;
      console.log('User UID:', uid);

      
      const docRef = doc(db, "users", user.uid);
      await setDoc(docRef,{
        fname: fname,
        lname:lname,
        email: user.email,
        role: role
        
      });
      console.log("Document written with ID: ", docRef.id);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <style>
        {`
        body {
          background-color: #add8e6;
          color: black;
        }
        `}
      </style>
      <Container style={{ marginTop:"100px", width:"400px" }}>
      <div className="p-4 box">
        <h2 className="mb-3">Welcome User</h2>
        
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formrole">
            <Form.Control
              type="fname"
              placeholder="First Name"
              onChange={(e) => setFname(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formrole">
            <Form.Control
              type="lname"
              placeholder="Last Name"
              onChange={(e) => setLname(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formrole">
            <Form.Control
              type="role"
              placeholder="Role"
              onChange={(e) => setRole(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Sign up
            </Button>
          </div>
        </Form>
        </div>
        {/* <div className="p-4 text-center">
        Already have an account? <Link to="/">Log In</Link>
      </div> */}
        </Container>
      
    </>
  );
};

export default Signup;
