import React from 'react';
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../../context/UserAuthContext';
import logo from '../../../assets/logo.png';
import './styles.css'; 
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';

const testingMethods = [
  {
    category: "Surface Inspections",
    tests: [
      { name: "MP Testing (MT)", path: "../technician/MPtesting" },
      { name: "Liquid Penetrant (PT)", path: "../technician/PTtesting" },
      { name: "Hardness Testing (HT)", path: "../technician/HTtesting" },
      { name: "Visual Testing (VT)", path: "../technician/VTtesting" },
      { name: "Ferrite Testing (FT)", path: "/ft" },
    ],
  },
  {
    category: "Radiographic Testing",
    tests: [
      { name: "Conventional Radiographic Testing", path: "../technician/ConventionalRadiographicTesting" },
      { name: "Computed Radiographic Testing", path: "../technician/ComputedRadiographicInspectionTesting" },
      { name: "Digital Radiographic Testing", path: "../technician/DigitalRadiographicTesting" },
    ],
  },
  {
    category: "Ultrasonic Testing",
    tests: [
      { name: "Ultrasonic Thickness Testing", path: "../technician/UTtesting" },
      { name: "Ultrasonic Testing Shear wave", path: "/ut-shear-wave" },
      { name: "Ultrasonic Testing AWS D1.1", path: "/../technician/UTDtesting" },
      { name: "Phased Array Testing", path: "/phased-array" },
    ],
  },
  {
    category: "Eddy Current Testing",
    tests: [
      { name: "Eddy Current Surface Inspections", path: "/et-surface" },
      { name: "Eddy Current Tubular Inspections", path: "/et-tubular" },
    ],
  },
  {
    category: "API Inspections",
    tests: [
      { name: "API 510", path: "/api-510" },
      { name: "API 570", path: "/api-570" },
      { name: "API 653", path: "/api-653" },
    ],
  },
  {
    category: "Specialty Methods",
    tests: [
      { name: "MFL Testing", path: "/mfl" },
      { name: "GUL Testing", path: "/gul" },
      { name: "Pipeline Integrity", path: "/pipeline-integrity" },
      { name: "Third Party Inspection", path: "/third-party" },
      { name: "Clad THK Testing", path: "../technician/CladTHKTesting" },
      { name: "PMI RPT Testing", path: "../technician/PMIrptTesting"},
    ],
  },
  // Add any additional categories and tests as needed
];


const TechnicianNavbar = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();

localStorage.removeItem('userRole'); 
 navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  // JSA validation and Certification validation
  const handleTestClick = async (path) => {
    // if (path.includes("RadiographicTesting")) {
    //   navigate(path); // Navigate directly to the Radiographic Testing page
    // } else {
    //   navigate('/technician/JobSafetyAnalysis' , { state: { selectedTestingMethod: path } }); // Navigate to JobSafetyAnalysis.js for other tests
    // }
    try {
      // Fetch certification based on technician's email
      const certificationsCollection = collection(db, 'Certifications');
      const querySnapshot = await getDocs(query(certificationsCollection, where("techName", "==", user.email.toLowerCase())));
      
      if (!querySnapshot.empty) {
        const certification = querySnapshot.docs[0].data();
        // Check if technican has experience
        if  (path === "../technician/MPtesting" && (certification.mtCertExp === 'no' || certification.mtCertExp === '')) {
          alert('This technician is not allowed to access this method.');
          return;
        }
        if  (path === "../technician/HTtesting" && (certification.htCertExp === 'no' || certification.htCertExp === '')) {
          alert('This technician is not allowed to access this method.');
          return;
        }
        if  (path === "../technician/PTtesting" && (certification.ptCertExp === 'no' || certification.ptCertExp === '')) {
          alert('This technician is not allowed to access this method.');
          return;
        }
        if  (path === "../technician/UTtesting" && (certification.uttCertExp === 'no' || certification.uttCertExp === '' )) {
          alert('This technician is not allowed to access this method.');
          return;
        }
        if  (path === "../technician/VTtesting" && (certification.vtCertExp === 'no' || certification.vtCertExp === '')) {
          alert('This technician is not allowed to access this method.');
          return;
        }
        if  (path === "../technician/CladTHKTesting" && (certification.cladThkCertExp === 'no'|| certification.cladThkCertExp === '')) {
          alert('This technician is not allowed to access this method.');
          return;
        }
        if  (path === "../technician/PMIrptTesting" && (certification.pmiRptCertExp === 'no' || certification.pmiRptCertExp === '')) {
          alert('This technician is not allowed to access this method.');
          return;
        }
      } else {
        alert('No certification found for this technician.');
        return;
      }
  
      if (path.includes("RadiographicTesting")) {
        navigate(path);
      } else {
        navigate('/technician/JobSafetyAnalysis', { state: { selectedTestingMethod: path } });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
// JSA validation and Certification validation
const handleReportClick = () => {
    navigate('/Reports');
  };
 
  return (
    <>
    <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#000080',color:'white' }} variant="dark" className="flex-lg-column">
      <Container fluid className="d-flex flex-column align-items-center flex-lg-row justify-content-lg-between w-100">
        <Navbar.Brand  className="mb-lg-0">
          <img
            src={logo}
            height="60" 
            className="d-inline-block align-top"
            alt="Logo"
          />
          
        </Navbar.Brand>
        <div>
          <Button variant="outline-light" onClick={handleReportClick} className="me-2">
            Reports
          </Button>
          <Button variant="outline-light" onClick={handleLogout} style={{ borderColor: 'red' }}>
            Logout
          </Button>
        </div>
      </Container >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto" >
            {testingMethods.map((method, index) => (
              <NavDropdown title={method.category} id={`dropdown-${method.category.toLowerCase().replace(/\s+/g, '-')}`} key={index}>
                {method.tests.map((test, testIndex) => (
                  <NavDropdown.Item onClick={() => handleTestClick(test.path)} key={testIndex}>
                    {test.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}
          </Nav>
        </Navbar.Collapse>
    </Navbar>
    </>
  );
};

export default TechnicianNavbar;
