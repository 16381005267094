// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const userRole = localStorage.getItem('userRole');
  console.log(userRole);
  if (!userRole || (allowedRoles && !allowedRoles.includes(userRole))) {
    
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
