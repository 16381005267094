import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddBox from '@mui/icons-material/AddBox';
import Pending from '@mui/icons-material/Pending';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Badge from '@mui/material/Badge'; 
import { useUserAuth } from '../../context/UserAuthContext'; // Adjust the import path as needed
import logo from '../../assets/logo.jpeg'; // Adjust the import path according to your project structure
import { db } from '../../firebase';
import { doc, getDocs,setDoc, updateDoc,addDoc, collection } from 'firebase/firestore';
import { Navbar, Container, Button, Nav } from "react-bootstrap";
import Logo from '../../assets/logo.jpeg';
import { makeStyles } from '@mui/styles';
import TechnicianNavbar from '../technician/Navigation/TechnicianNavbar';




// Custom makeStyles hook for the component styles
const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    marginTop: '100px',
    borderRadius: 5,
    boxShadow: '0 8px 18px rgba(0, 0, 0, 0.4)',
    transition: 'transform 0.3s ease-in-out',
    marginLeft: '2px',
    '&:hover': {
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
      transform: 'scale(1.05)',
    },
  },
  media: {
    height: 140,
  },
  logo: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.5,
    width: '300px', // Control the size of the logo
    height: 'auto',
    zIndex: -1, // Ensure the logo does not interfere with other clickable elements
  },

  navbar: {
    position: 'right',
    left: '1000px',
    right: '2px', // Adjust the right position as needed
    top: '2px', // Adjust the top position as needed
  },

  animationText: {
    animation: '$fadeInOut 5s ease-in-out infinite',
    textAlign: 'center',
    marginTop: '0px',
    marginLeft: '60px'
  },
 // Define keyframe animation for fadeInOut
 '@keyframes fadeInOut': {
  '0%': {
    opacity: 0,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
},
});

const AdminHome = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { logOut } = useUserAuth(); 
  const [pendingRequestsCount, setPendingRequestsCount] = useState(0);

  useEffect(() => {
    const fetchPendingRequestsCount = async () => {
      try {
        // Fetch the count of pending requests from Firestore
        
        const pendingRequestsSnapshot = await getDocs(collection(db, "PendingRequests"))
        setPendingRequestsCount(pendingRequestsSnapshot.size);
      } catch (error) {
        console.error('Error fetching pending requests count:', error);
      }
    };

    fetchPendingRequestsCount();
  }, []);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/'); // Redirect to login after logout
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, position: 'relative', textAlign: 'center', overflow: 'hidden' }}>    
    <img src={logo} alt="Logo" style={{ position: 'absolute', top: '0px', left: '0px', width: '188px', height: 'auto', zIndex: 1 }} />
              
      {/* Logo as background with adjusted z-index */}      
    <style>
      {`
        body {
          background-color: #add8e6;
          color: black;
        }
        .MuiAppBar-root {
          background-color: navy;
        }
      `}
    </style> {/* Add CSS for body */}

    <Navbar style={{ backgroundColor: '#000080' , color:'white'}}  variant="dark" expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand  className="justify-content-start">
            <img
              src={Logo}
              height="60" 
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
            <Nav style={{ marginLeft:'220px', fontSize:'20px'}} >
            
            </Nav>
            <Button variant="outline-danger" onClick={handleLogout}>Logout</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
<div style = {{marginTop:'20px'}} className="text-center">
        <h3> Welcome to Admin Dashboard</h3>
      </div>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 4, p: 4, flexWrap: 'wrap', mt: 8 }}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, backgroundColor: 'background.paper', ':hover': { boxShadow: `0 8px 16px 0 rgb(24,118,209)`, transform: 'scale(1.05)' }, transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}>
          <CardActionArea onClick={() => navigate('/Signup')}>
            <CardContent sx={{ textAlign: 'center' }}>
              <PersonAddIcon fontSize="large" />
              <Typography variant="h6" component="div">
                Add Users
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Create new user accounts.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, backgroundColor: 'background.paper', ':hover': { boxShadow: `0 8px 16px 0 rgb(24,118,209)`, transform: 'scale(1.05)' }, transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}>
          <CardActionArea onClick={() => navigate('/Reports')}>
            <CardContent sx={{ textAlign: 'center' }}>
              <AssessmentIcon fontSize="large" />
              <Typography variant="h6" component="div">
                See Reports
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                View and analyze reports.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, backgroundColor: 'background.paper', ':hover': { boxShadow: `0 8px 16px 0 rgb(24,118,209)`, transform: 'scale(1.05)' }, transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}>
          <CardActionArea onClick={() => navigate('/admin/pending')}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Badge badgeContent={pendingRequestsCount} color="error"> {/* Badge displaying the count of pending requests */}
                <Pending fontSize="large" />
              </Badge>
              <Typography variant="h6" component="div">
                Pending Requests
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                View pending report requests.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, backgroundColor: 'background.paper', ':hover': { boxShadow: `0 8px 16px 0 rgb(24,118,209)`, transform: 'scale(1.05)' }, transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}>
          <CardActionArea onClick={() => navigate('/admin/Equipments')}>
            <CardContent sx={{ textAlign: 'center' }}>
              <AddBox fontSize="large" />
              <Typography variant="h5" component="div">
                Add Equipments
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Add Equipments
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, backgroundColor: 'background.paper', ':hover': { boxShadow: `0 8px 16px 0 rgb(24,118,209)`, transform: 'scale(1.05)' }, transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}>
          <CardActionArea onClick={() => navigate('/admin/Certification')}>
            <CardContent sx={{ textAlign: 'center' }}>
              <AddBox fontSize="large" />
              <Typography variant="h5" component="div">
                Technician Certifications
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Technician Certifications
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, backgroundColor: 'background.paper', ':hover': { boxShadow: `0 8px 16px 0 rgb(24,118,209)`, transform: 'scale(1.05)' }, transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}>
          <CardActionArea onClick={() => navigate('../technician/Home')}>
            <CardContent sx={{ textAlign: 'center' }}>
              <AddBox fontSize="large" />
              <Typography variant="h5" component="div">
                Technician Home Page
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Technician Home Page
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </Box>
  );
};

export default AdminHome;
