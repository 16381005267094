import React from "react";
import { Navbar, Container, Button, Nav } from "react-bootstrap";
import ClientReports from "./ClientReports";
import Logo from '../../assets/logo.jpeg'; // Import the logo
import { useUserAuth } from '../../context/UserAuthContext'; // Adjust the import path as needed
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const { logOut } = useUserAuth();

  const handleLogout = async () => {
    console.log("Logout clicked");        
    try {
      await logOut();
      navigate('/'); 
    } catch (error) {
      console.error('Failed to log out:', error);
    }        
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Navbar style={{ backgroundColor: '#000080' , color:'white'}}  variant="dark" expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand  className="justify-content-start">
            <img
              src={Logo}
              height="60" 
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
            <Nav style={{ marginLeft:'220px', fontSize:'20px'}} >
            
            </Nav>
            <Button variant="outline-danger" onClick={handleLogout}>Logout</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
      <div style = {{marginTop:'20px'}} className="text-center">
        <h3> Welcome to Client Dashboard</h3>
      </div>
      <div className="fade-text text-center mt-4">
        <h2 style={{ fontSize: "36px" }}>ECS INTEGRITY</h2>
        <h4 style={{ fontSize: "20px" }}>
          Driving Efficiency and Value through Innovative Design and Engineering.<br />
          Forging the way to a new inspection standard.
        </h4>
      </div>
      <ClientReports />
      
      <style>
        {`
        body {
          background-color: #add8e6;
          color: black;
        }
          .fade-text {
            animation: fadeInOut 5s ease-in-out infinite;
          }
          
          @keyframes fadeInOut {
            0% { opacity: 0; }
            50% { opacity: 1; }
            100% { opacity: 0; }
          }
        `}
      </style>
    </div>
  );
};

export default Home;
