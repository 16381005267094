import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc,setDoc, updateDoc,addDoc, collection } from 'firebase/firestore';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';


const EditTest = () => {
  const { reportId } = useParams();
  const [changedFields, setChangedFields] = useState(new Set());
  const [formValues, setFormValues] = useState({
    reportNumber: '',
    date: '',
    revision: '',
    page: '',
    purchaseOrder: '',
    contactPerson: '',
    remarks: '',
    technician: '',
    assistant: '',
    consumables: '',
    clientUse: '',
    certifyingAuthority: ''
  });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchReport = async () => {
      const docRef = doc(db, 'Reports', reportId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const reportData = docSnap.data();
        // Separate 'rows' field from other data
        const { rows, ...otherData } = reportData;
        // Set form values and rows
        setFormValues(otherData);
        setRows(rows || []); // If rows is null, set it to an empty array
      } else {
        console.log('No such document!');
      }
    };

    fetchReport();
  }, [reportId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setChangedFields(new Set(changedFields.add(name)));
  };

  const handleAddRow = () => {
    const newRow = { item: '', qty: '', heatNo: '', serialLoad: '', description: '', results: '' };
    setRows([...rows, newRow]);
  };

  const handleRowChange = (idx, field, value) => {
    const updatedRows = rows.map((row, ridx) => (ridx === idx ? { ...row, [field]: value } : row));
    setRows(updatedRows);
  };

  const handleRemoveRow = (idx) => {
    setRows(rows.filter((_, ridx) => ridx !== idx));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
       const changed_fields = Array.from(changedFields);
        const pendingRequestsRef = collection(db, 'PendingRequests');
        const newRequestDocRef = doc(pendingRequestsRef, reportId);
        await setDoc(newRequestDocRef, {
          ...formValues,
          rows,
          changed_fields
        });
  
      console.log('Request submitted with ID:', newRequestDocRef.id);
      alert('Request submitted successfully!');
    } catch (error) {
      console.error('Error submitting request:', error);
      alert('Error submitting request. Please try again.');
    }
  };

  return (
    <>
    <style>
        {`
        body {
          background-color: #add8e6;
          color: black;
        }
        `}
      </style>
    <Container style={{ alignItems: "flex-start" }} className="mt-5">
      <h2>Edit Test</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formReportNumber">
              <Form.Label>Report Number</Form.Label>
              <Form.Control
                type="text"
                name="reportNumber"
                value={formValues.reportNumber}
                onChange={handleInputChange}
                readOnly // Make it read-only
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formValues.date}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formRevision">
              <Form.Label>Revision</Form.Label>
              <Form.Control
                type="text"
                name="revision"
                value={formValues.revision}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPage">
              <Form.Label>Page</Form.Label>
              <Form.Control
                type="text"
                name="page"
                value={formValues.page}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formPurchaseOrder">
              <Form.Label>Purchase Order</Form.Label>
              <Form.Control
                type="text"
                name="purchaseOrder"
                value={formValues.purchaseOrder}
                onChange={handleInputChange}
                placeholder="Enter purchase order"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formContactPerson">
              <Form.Label>Contact Person</Form.Label>
              <Form.Control
                type="text"
                name="contactPerson"
                value={formValues.contactPerson}
                onChange={handleInputChange}
                placeholder="Enter contact person"
              />
            </Form.Group>
          </Col>
        </Row>
        {/* Dynamic Rows Section */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
              <th>Heat#</th>
              <th>Serial/Load PTH#</th>
              <th>Description</th>
              <th>Results</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, idx) => (
              <tr key={idx}>
                <td><Form.Control type="text" value={row.item} onChange={(e) => handleRowChange(idx, 'item', e.target.value)} /></td>
                <td><Form.Control type="number" value={row.qty} onChange={(e) => handleRowChange(idx, 'qty', e.target.value)} /></td>
                <td><Form.Control type="text" value={row.heatNo} onChange={(e) => handleRowChange(idx, 'heatNo', e.target.value)} /></td>
                <td><Form.Control type="text" value={row.serialLoad} onChange={(e) => handleRowChange(idx, 'serialLoad', e.target.value)} /></td>
                <td><Form.Control type="text" value={row.description} onChange={(e) => handleRowChange(idx, 'description', e.target.value)} /></td>
                <td><Form.Control type="text" value={row.results} onChange={(e) => handleRowChange(idx, 'results', e.target.value)} /></td>
                <td><Button variant="danger" onClick={() => handleRemoveRow(idx)}>Remove</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button variant="primary" onClick={handleAddRow} className="mb-3">Add Test Entry</Button>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formRemarks">
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="remarks"
                value={formValues.remarks}
                onChange={handleInputChange}
                placeholder="Enter remarks"
              />
            </Form.Group>
          </Col>
          </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formTechnician">
              <Form.Label>Technician</Form.Label>
              <Form.Control
                type="text"
                name="technician"
                value={formValues.technician}
                onChange={handleInputChange}
                placeholder="Technician name"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formAssistant">
              <Form.Label>Assistant</Form.Label>
              <Form.Control
                type="text"
                name="assistant"
                value={formValues.assistant}
                onChange={handleInputChange}
                placeholder="Assistant name"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formConsumables">
              <Form.Label>Consumables</Form.Label>
              <Form.Control
                type="text"
                name="consumables"
                value={formValues.consumables}
                onChange={handleInputChange}
                placeholder="List consumables"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formClientUse">
              <Form.Label>Client Use</Form.Label>
              <Form.Control
                type="text"
                name="clientUse"
                value={formValues.clientUse}
                onChange={handleInputChange}
                placeholder="Specify if for client use"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formCertifyingAuthority">
              <Form.Label>Certifying Authority</Form.Label>
              <Form.Control
                type="text"
                name="certifyingAuthority"
                value={formValues.certifyingAuthority}
                onChange={handleInputChange}
                placeholder="Enter certifying authority"
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="success" type="submit">Save</Button>
      </Form>
    </Container>
    </>
  );
};

export default EditTest;

