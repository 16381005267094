import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Table, Row, Col , Modal} from 'react-bootstrap';
import { doc, setDoc, collection, getDocs, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { db } from '../../firebase'; 
import Logo from '../../assets/logo.jpeg'; // Import the logo
import { Link, useNavigate } from 'react-router-dom';
import { storage } from '../../firebase'; // Image upload 

const ViewEquipments = () => {
  const [equipments, setEquipments] = useState([]);
  const [formValues, setFormValues] = useState({
    equipmentName: '',
    manufacturingDate: '',
    equipmentSerialNumber: '',
    calibrationDate: '',
    calibrationDueDate: '',
    testingMethod: '',
    tFrequency: '',
    instrumentsManufacturer: '',
    tSize: '',
    equipmentType: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  // Image upload code
  const [imageUrl, setImageUrl] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  // Image upload code

  useEffect(() => {
    fetchEquipments();
  }, []);

  const fetchEquipments = async () => {
    const equipmentsCollection = collection(db, 'Equipments');
    const snapshot = await getDocs(equipmentsCollection);
    const equipmentsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setEquipments(equipmentsData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSaveDraft = async () => {
    if (!formValues.equipmentSerialNumber) {
      alert('Serial number is required.');
      return;
    }

    try {
      const equipmentRef = doc(db, 'draftEquipments', formValues.equipmentSerialNumber);
      await setDoc(equipmentRef, { ...formValues });
      console.log('Draft saved with Equipment Serial Number:', formValues.equipmentSerialNumber);
      alert('Draft saved successfully!');
    } catch (error) {
      console.error('Error saving draft:', error);
      alert('Error saving draft. Please try again.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formValues.equipmentSerialNumber) {
      alert('Serial Number is required.');
      return;
    }

    try {
      const equipmentRef = doc(db, 'Equipments', formValues.equipmentSerialNumber);
      await setDoc(equipmentRef, { ...formValues });

      console.log('Equipment saved with Equipment Serial Number:', formValues.equipmentSerialNumber);
      alert('Equipment saved successfully!');
      setFormValues({
        equipmentName: '',
        manufacturingDate: '',
        equipmentSerialNumber: '',
        calibrationDate: '',
        calibrationDueDate: '',
        testingMethod: '',
        tFrequency: '',
        instrumentsManufacturer: '',
        tSize: '',
        equipmentType: ''
      });
      fetchEquipments(); // Update the list of equipments
    } catch (error) {
      console.error('Error saving equipment:', error);
      alert('Error saving equipment. Please try again.');
    }
  };

  const handleEdit = (equipment) => {
    setFormValues({ ...equipment });
  };

  const handleDelete = async (equipmentId) => {
    try {
      await deleteDoc(doc(db, 'Equipments', equipmentId));
      console.log('Equipment deleted:', equipmentId);
      alert('Equipment deleted successfully!');
      fetchEquipments(); // Update the list of equipments
    } catch (error) {
      console.error('Error deleting equipment:', error);
      alert('Error deleting equipment. Please try again.');
    }
  };
  // Image Upload code
  const handleUploadImage = async (equipmentId) => {
    try {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      // fileInput.click();
  
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        const storageRef = await import('firebase/storage').then((storage) => storage.getStorage());
        const imagesRef = await import('firebase/storage').then((storage) => storage.ref(storageRef, `equipment_images/${equipmentId}/${file.name}`));

        
        try {
          await import('firebase/storage').then((storage) => storage.uploadBytes(imagesRef, file));
          const imageUrl = await import('firebase/storage').then((storage) => storage.getDownloadURL(imagesRef));
          console.log(imageUrl)
  
        // Update the equipment with the image URL
          const equipmentRef = doc(db, 'Equipments', equipmentId);
          console.log(equipmentRef)
          await updateDoc(equipmentRef, { imageUrl });
  
          console.log('Image uploaded successfully:', imageUrl);
          alert('Image uploaded successfully!');
          setImageUrl(imageUrl);
      } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error uploading image. Please try again.');
    }
  };
    fileInput.click();
  }
  catch (error) {
    console.error('Error setting up file input:', error);
    alert('Error setting up file input. Please try again.');
  }
};
// const DisplayUploadedImage = () => {
//   if (imageUrl) {
//     return (
//       <div>
//         <h3>View Uploaded Image</h3>
//         <img src={imageUrl} alt="Uploaded Equipment" style={{ maxWidth: '100%', height: 'auto' }} />
//       </div>
//     );
//   } else {
//     return null;
//   }
// };
const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Uploaded Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={imageUrl} alt="Uploaded Equipment" style={{ maxWidth: '100%', height: 'auto' }} />
      </Modal.Body>
    </Modal>
  );
};
  // Image Upload code

  const filteredEquipments = equipments.filter(equipment =>
    equipment.equipmentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    equipment.equipmentSerialNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
    {/* <DisplayUploadedImage /> */}
    <ImageModal imageUrl={imageUrl} onClose={() => setShowImageModal(false)} />
      <style>
        {`
          body {
            background-color: #add8e6;
          }
        `}
      </style>
      <Container style={{ alignItems: "flex-start" }} className="mt-5">
        <br></br>
        <h2>Equipment Maintenance</h2>
        <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
          <Form.Group controlId="formEquipmentName">
              <Form.Label>Equipment Name</Form.Label>
              <Form.Control
                type="text"
                name="equipmentName"
                value={formValues.equipmentName}
                onChange={handleInputChange}
                placeholder="Enter Equipment Name"
              />
            </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="formEquipmentSerialNumber">
              <Form.Label>Equipment Serial Number</Form.Label>
              <Form.Control
                type="text"
                name="equipmentSerialNumber"
                value={formValues.equipmentSerialNumber}
                onChange={handleInputChange}
                placeholder="Serial Number is required"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formManufacturingDate">
              <Form.Label>Manufacturing Date</Form.Label>
              <Form.Control
                type="date"
                name="manufacturingDate"
                value={formValues.manufacturingDate}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formCalibrationDate">
              <Form.Label>Calibration Date</Form.Label>
              <Form.Control
                type="date"
                name="calibrationDate"
                value={formValues.calibrationDate}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formCalibrationDueDate">
              <Form.Label>Calibration Due Date</Form.Label>
              <Form.Control
                type="date"
                name="calibrationDueDate"
                value={formValues.calibrationDueDate}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formTestingMethod">
              <Form.Label>Testing Method</Form.Label>
              <Form.Select
                name="testingMethod"
                value={formValues.testingMethod}
                onChange={handleInputChange}
                placeholder="Select testing method"
                >
                  <option value="">Select Testing Method</option>
                  <option value="surface inspections-mp testing">Surface Inspections - MP testing</option>
                  <option value="surface inspections-ptesting">Surface Inspections - PT testing</option>
                  <option value="surface inspections-htesting">Surface Inspections - HT testing</option>
                  <option value="surface inspections-vtesting">Surface Inspections - VT testing</option>
                  <option value="surface inspections-ftesting">Surface Inspections - FT testing</option>
                  <option value="radiographic testing">Radiographic Testing</option>
                  <option value="ultrasonic testing">Ultrasonic Testing</option>
                  <option value="eddy current testing">Eddy Current Testing</option>
                  <option value="api inspections">API Inspections</option>
                  <option value="speciality methods-clad thk testing">Speciality Methods - CLAD THK testing</option>
                  <option value="speciality methods-pmi rpt testing">Speciality Methods - PMI RPT testing</option>
          </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
          <Form.Group controlId="formTFrequency">
              <Form.Label>T Frequency (MHz)</Form.Label>
              <Form.Control
                type="text"
                name="tFrequency"
                value={formValues.tFrequency}
                onChange={handleInputChange}
                placeholder="Enter Frequency"
              />
            </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="formInstrumentsManufacturer">
              <Form.Label>Instrument Manufacturer</Form.Label>
              <Form.Control
                type="text"
                name="instrumentsManufacturer"
                value={formValues.instrumentsManufacturer}
                onChange={handleInputChange}
                placeholder="Serial Manufacturer"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formTSize">
              <Form.Label>T Size (in)</Form.Label>
              <Form.Control
                type="text"
                name="tSize"
                value={formValues.tSize}
                onChange={handleInputChange}
                placeholder="Enter Size"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formEquipmentType">
              <Form.Label>Equipment Type</Form.Label>
              <Form.Control
                type="text"
                name="equipmentType"
                value={formValues.equipmentType}
                onChange={handleInputChange}
                placeholder="Enter Type of Equipment"
              />
            </Form.Group>
          </Col>
        </Row>
        <img src={Logo} alt="Logo" style={{ position: 'absolute', top: '4.5px', left: '8px', width: '150px', height: 'auto', zIndex: 1 }}/>
          {/* <Button style={{ marginRight: '20px', marginBottom: "20px" }} variant="secondary" onClick={handleSaveDraft}>Save Draft</Button> */}
          <Button style={{ marginBottom: "20px" }} type="submit" variant="success">Submit</Button>
        </Form>

        
        {/* Search input field */}
        <Form.Group controlId="formSearch">
          <Form.Control
            type="text"
            placeholder="Search by Equipment Name or Serial Number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginBottom: '10px',
              fontSize: '16px',
              boxSizing: 'border-box'
            }}
          />
        </Form.Group>

        {/* Table to display equipments */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Equipment Name</th>
              <th>Manufacturing Date</th>
              <th>Serial Number</th>
              <th>Calibration Date</th>
              <th>Calibration Due Date</th>
              <th>Testing Method</th>
              <th>Instruments Manufacturer</th>
              <th>Equipment Type</th>
              <th>T Frequency (MHz)</th>
              <th>T Size (in)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredEquipments.map(equipment => (
              <tr key={equipment.id}>
                <td>{equipment.equipmentName}</td>
                <td>{equipment.manufacturingDate}</td>
                <td>{equipment.equipmentSerialNumber}</td>
                <td>{equipment.calibrationDate}</td>
                <td>{equipment.calibrationDueDate}</td>
                <td>{equipment.testingMethod}</td>
                <td>{equipment.instrumentsManufacturer}</td>
                <td>{equipment.equipmentType}</td>
                <td>{equipment.tFrequency}</td>
                <td>{equipment.tSize}</td>
                <td>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', gap: '10px' }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button style={{ width: '60px', height: '40px', textAlign: 'center', backgroundColor: '#007bff', color: 'white'}} variant="primary" onClick={() => handleEdit(equipment)}>Edit</Button>
                  <Button style={{ width: '80px', height: '40px', textAlign: 'right', lineHeight: '20px', backgroundColor: '#dc3545', color: 'white'}} variant="danger" onClick={() => handleDelete(equipment.id)}>Delete</Button>
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button style={{ width: '80px', height: '40px', backgroundColor: '#6c757d', color: 'white'}} variant="secondary" onClick={() => handleUploadImage(equipment.id)}>Upload</Button>
                  {equipment.imageUrl && (<Button style={{ width: '60px', height: '40px', backgroundColor: '#28a745', color: 'white' }} variant="primary" onClick={() => {setImageUrl(equipment.imageUrl); setShowImageModal(true);}}>View</Button>)}                
                  </div>
                </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default ViewEquipments;
