import React, { useState, useEffect  } from 'react';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { Link, useNavigate, useLocation} from 'react-router-dom';


const JobSafetyAnalysis = () => {
  const navigate = useNavigate();
  // JSA validation
  const location = useLocation();
  //JSA validation

  const [formValues, setFormValues] = useState({
    client: '',
    dateOrTime: '',
    customerPO: '',
    workDetails: '',
    siteContact: '',
    phoneNumber: '',
    siteEmergencyNumber: '',
    firstAidKit: '',
    eyeWashStation: '',
    safetyShower: '',
    emergencyExit: '',
    fireExtinguisher: '',
    medicalOrPhysical: '',
    stressOrFatigue: '',
    completedReqTraining: '',
    fitToWork: '',
    confinedSpace: '',
    outdoors: '',
    poorHousekeeping: '',
    trenches: '',
    hotWork: '',
    overheadWork: '',
    heights: '',
    blockedExits: '',
    movingEquipment: '',
    personnelCleared: '',
    barricadesBeforeWork: '',
    barricadesUponCompletion: '',
    sourceCleared: '',
    employeeName: '',
    empSignature: '',
    supervisorName: '',
    supervisorSignature: '',
    date: '',
    additionalNotes: ''
  });

  const hazards = [
    "Chemical Hazard/Spills",
    "Weather Conditions",
    "Noise Exposure",
    "Restricted Access",
    "Lighting",
    "Flying Debris",
    "Ground Condition",
    "People working in area",
    "Moving Vehicle",
    "Pinch Points",
    "Ladders/Scaffolds",
    "Ventilation",
    "Flammables/Explosives",
    "Radiation",
    "Tool/Equipment Condition",
    "Lock Out/Tag Out",
    "PPE Condition"
  ];

  const riskLevels = ["High", "Medium", "Low"];

  // State to store corrective actions and risk levels
  const [correctiveActions, setCorrectiveActions] = useState(new Array(hazards.length).fill(''));
  const [riskValues, setRiskValues] = useState(new Array(hazards.length).fill(''));
  // Handler for updating corrective actions
  const handleCorrectiveActionChange = (index, value) => {
    const newCorrectiveActions = [...correctiveActions];
    newCorrectiveActions[index] = value;
    setCorrectiveActions(newCorrectiveActions);
  };
  // Handler for updating risk levels
  const handleRiskLevelChange = (index, value) => {
    const newRiskValues = [...riskValues];
    newRiskValues[index] = value;
    setRiskValues(newRiskValues);
  };


  useEffect(() => {
    
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const handleSaveDraft = async () => {
    if (!formValues.customerPO) {
      alert('Purchase Order is required.');
      return;
    }

    try {
      const jsareportRef = doc(db, 'draftJSAReports', formValues.customerPO);
      await setDoc(jsareportRef, {
        ...formValues,
      });

      console.log('Draft saved with Purchase Order:', formValues.customerPO);
      alert('Draft saved successfully!');
    } catch (error) {
      console.error('Error saving draft:', error);
      alert('Error saving draft. Please try again.');
    }
  };

  const handleSubmitJSA = async (event) => {
    event.preventDefault();
    if (!formValues.customerPO) {
        alert('Purchase Order is required.');
        return;
    }
  
      try {
        const jsareportRef = doc(db, 'JSAReports', formValues.customerPO);
        await setDoc(jsareportRef, {
          ...formValues, 
        });
  
        console.log('Saved with Purchase Order:', formValues.customerPO);
          alert('Saved successfully!');
          
      } catch (error) {
        console.error('Error saving:', error);
        alert('Error saving. Please try again.');
      }
    // JSA validation
      const selectedTestingMethod = location.state && location.state.selectedTestingMethod;
    if (selectedTestingMethod) {
      navigate(selectedTestingMethod);
    } else {
      alert('Please select a testing method before submitting.');
    }
     // JSA validation
  };

  return (
    <>
    <style>
      {`
        body {
          background-color: #add8e6;
             }
        `}
        </style>
    <Container style={ {alignItems:"flex-start"}}className="mt-5">
      <h2>Job Safety Analysis Report</h2>
      <h4>Jobsite Information</h4>
      <Form onSubmit={handleSubmitJSA}>
        <Row className="mb-3">
          <Col>
          <Form.Group controlId="formClient">
              <Form.Label>Client</Form.Label>
              <Form.Control
                type="text"
                name="client"
                value={formValues.client}
                onChange={handleInputChange}
                placeholder="Enter Client Name"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formDateOrTime">
              <Form.Label>Date/Time</Form.Label>
              <Form.Control
                type="date"
                name="dateOrTime"
                value={formValues.dateOrTime}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formCutomerPO">
              <Form.Label>Customer PO/ECS WO#</Form.Label>
              <Form.Control
                type="text"
                name="customerPO"
                value={formValues.customerPO}
                onChange={handleInputChange}
                placeholder="Enter Work Order Number"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formWorkDetails">
              <Form.Label>Work Details/Scope</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="workDetails"
                value={formValues.workDetails}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
  <h4>Emergency Contacts</h4>
  <Row className="mb-3">
  <Col>
    <Form.Group controlId="formSiteContact">
      <Form.Label>Site Contact</Form.Label>
      <Form.Control
        type="text"
        name="siteContact"
        value={formValues.siteContact}
        onChange={handleInputChange}
        placeholder="Enter Contact"
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formPhoneNumber">
      <Form.Label>Phone Number</Form.Label>
      <Form.Control
        type="text"
        name="phoneNumber"
        value={formValues.phoneNumber}
        onChange={handleInputChange}
        placeholder="Enter Phone Number"
      />
    </Form.Group>
  </Col>
</Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formSiteEmergencyNumber">
              <Form.Label>Site Emergency Number</Form.Label>
              <Form.Control
                type="text"
                name="siteEmergencyNumber"
                value={formValues.siteEmergencyNumber}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <h4>Safety Locations Identified</h4>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formFirstAidKit">
      <Form.Label>First Aid Kit</Form.Label>
      <Form.Check
        type="checkbox"
        name="firstAidKit"
        // checked={formValues.firstAidKit}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formEyeWashStation">
      <Form.Label>Eye Wash Station</Form.Label>
      <Form.Check
        type="checkbox"
        name="eyeWashStation"
        // checked={formValues.eyeWashStation}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formSafetyShower">
      <Form.Label>Safety Shower</Form.Label>
      <Form.Check
        type="checkbox"
        name="safetyShower"
        // checked={formValues.safetyShower}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Col>
</Row>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formEmergencyExit">
      <Form.Label>Emergency Exit</Form.Label>
      <Form.Check
        type="checkbox"
        name="emergencyExit"
        // checked={formValues.emergencyExit}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formFireExtinguisher">
      <Form.Label>Fire Extinguisher</Form.Label>
      <Form.Check
        type="checkbox"
        name="fireExtinguisher"
        // checked={formValues.fireExtinguisher}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Col>
</Row>
<h4>Personnel Hazard Awareness</h4>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formMedicalOrPhysicalLimits">
      <Form.Label>Medical/Physical Limits</Form.Label>
      <Form.Control
        as="select"
        name="medicalOrPhysical"
        value={formValues.medicalOrPhysical}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formStressOrFatigue">
      <Form.Label>Stress/Fatigue</Form.Label>
      <Form.Control
        as="select"
        name="stressOrFatigue"
        value={formValues.stressOrFatigue}
        onChange={handleInputChange}
        >
        <option value="Y">Y</option>
        <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  </Row>
  <Row className="mb-3">
  <Col>
    <Form.Group controlId="formCompletedReqTraining">
      <Form.Label>Completed Req. Training</Form.Label>
      <Form.Control
        as="select"
        name="completedReqTraining"
        value={formValues.completedReqTraining}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formFitToWork">
      <Form.Label>Fit to Work</Form.Label>
      <Form.Control
        as="select"
        name="fitToWork"
        value={formValues.fitToWork}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
</Row>
<h4>Area Hazard Awareness</h4>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formConfinedSpace">
      <Form.Label>Confined Space</Form.Label>
      <Form.Control
        as="select"
        name="confinedSpace"
        value={formValues.confinedSpace}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formOutdoors">
      <Form.Label>Outdoors</Form.Label>
      <Form.Control
        as="select"
        name="outdoors"
        value={formValues.outdoors}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formPoorHousekeeping">
      <Form.Label>Poor Housekeeping</Form.Label>
      <Form.Control
        as="select"
        name="poorHousekeeping"
        value={formValues.poorHousekeeping}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  </Row>
  <Row className="mb-3">
  <Col>
    <Form.Group controlId="formTrenches">
      <Form.Label>Trenches</Form.Label>
      <Form.Control
        as="select"
        name="trenches"
        value={formValues.trenches}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formHotWork">
      <Form.Label>Hot Work</Form.Label>
      <Form.Control
        as="select"
        name="hotWork"
        value={formValues.hotWork}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formOverheadWork">
      <Form.Label>Overhead Work</Form.Label>
      <Form.Control
        as="select"
        name="overheadWork"
        value={formValues.overheadWork}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
</Row>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formHeights">
      <Form.Label>Heights</Form.Label>
      <Form.Control
        as="select"
        name="heights"
        value={formValues.heights}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formBlockedExits">
      <Form.Label>Blocked Exits</Form.Label>
      <Form.Control
        as="select"
        name="blockedExits"
        value={formValues.blockedExits}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formMovingEquipment">
      <Form.Label>Moving Equipment</Form.Label>
      <Form.Control
        as="select"
        name="movingEquipment"
        value={formValues.movingEquipment}
        onChange={handleInputChange}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
      </Form.Control>
    </Form.Group>
  </Col>
</Row>

        {/* Dynamic Rows Section */}
    <h4>Area Hazard Control</h4>
    <table className="hazard-table">
      <thead>
        <tr>
          <th>Hazard</th>
          <th>Risk (High/Medium/Low)</th>
          <th>Corrective Actions</th>
        </tr>
      </thead>
      <tbody>
        {hazards.map((hazard, index) => (
          <tr key={index}>
            <td>{hazard}</td>
            <td><select value={riskValues[index]} onChange={e => handleRiskLevelChange(index, e.target.value)}>
                {riskLevels.map((level, i) => (
                  <option key={i} value={level}>{level}</option>
                ))}
              </select></td>
            <td><input 
                type="text" 
                value={correctiveActions[index]} 
                onChange={e => handleCorrectiveActionChange(index, e.target.value)} 
              /></td>
          </tr>
        ))}
      </tbody>
    </table>
        
<h4>Radiography Awareness</h4>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formPersonnelCleared">
      <Form.Label>Personnel Cleared</Form.Label>
      <Form.Check
        type="checkbox"
        name="personnelCleared"
        // checked={formValues.personnelCleared}
        onChange={handleInputChange}
        label="Check if Personnel Cleared"
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formBarricadesBeforeWork">
      <Form.Label>Barricades/Signage placed before Work begins</Form.Label>
      <Form.Check
        type="checkbox"
        name="barricadesBeforeWork"
        // checked={formValues.barricadesBeforeWork}
        onChange={handleInputChange}
        label="Check if barricades/signage placed before work begins"
      />
    </Form.Group>
  </Col>
</Row>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formSourceCleared">
      <Form.Label>Source Cleared</Form.Label>
      <Form.Check
        type="checkbox"
        name="sourceCleared"
        // checked={formValues.sourceCleared}
        onChange={handleInputChange}
        label="Check if Source Cleared"
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formBarricadesBeforeWork">
      <Form.Label>Barricades/Signage taken down upon completion</Form.Label>
      <Form.Check
        type="checkbox"
        name="barricadesUponCompletion"
        // checked={formValues.barricadesUponCompletion}
        onChange={handleInputChange}
        label="Check if barricades/signage taken down upon completion"
      />
    </Form.Group>
  </Col>
</Row>
<h4>JSA Completed by</h4>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formEmployeeName">
      <Form.Label>Employee Name</Form.Label>
      <Form.Control
        type="text"
        name="employeeName"
        value={formValues.employeeName}
        onChange={handleInputChange}
        placeholder="Employee name"
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formEmpSignature">
      <Form.Label>Signature</Form.Label>
      <Form.Control
        type="text"
        name="empSignature"
        value={formValues.empSignature}
        onChange={handleInputChange}
        placeholder="Employee Signature"
      />
    </Form.Group>
  </Col>
</Row>
<h4>Supervisor Review</h4>
<Row className="mb-3">
  <Col>
    <Form.Group controlId="formSupervisorName">
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        name="supervisorName"
        value={formValues.supervisorName}
        onChange={handleInputChange}
        placeholder="Enter Supervisior"
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formSupervisorSignature">
      <Form.Label>Signature</Form.Label>
      <Form.Control
        type="text"
        name="supervisorSignature"
        value={formValues.supervisorSignature}
        onChange={handleInputChange}
        placeholder="Write the signature"
      />
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formDate">
      <Form.Label>Date</Form.Label>
      <Form.Control
        type="date"
        name="date"
        value={formValues.date}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Col>
</Row>

<Row className="mb-3">
  <Col>
    <Form.Group controlId="formAdditionalNotes">
      <Form.Label>Additional Notes</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        name="additionalNotes"
        value={formValues.additionalNotes}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Col>
</Row>        
              <Button style={{marginRight:'20px',marginBottom:"20px"} } variant="secondary" onClick={handleSaveDraft}>Save Draft</Button>
              <Button style={{ marginBottom: "20px" }} type="submit" variant="success">Submit</Button>
        
      </Form>
    </Container>
    </>
  );
};

export default JobSafetyAnalysis;
