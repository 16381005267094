import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Card, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../assets/logo.png';
import TechnicianNavbar from './technician/Navigation/TechnicianNavbar';
import { makeStyles } from '@mui/styles';



// Custom makeStyles hook for the component styles
const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    marginTop: '100px',
    borderRadius: 5,
    boxShadow: '0 8px 18px rgba(0, 0, 0, 0.4)',
    transition: 'transform 0.3s ease-in-out',
    marginLeft: '2px',
    '&:hover': {
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
      transform: 'scale(1.05)',
    },
  },
  media: {
    height: 140,
  },
  logo: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.5,
    width: '300px', // Control the size of the logo
    height: 'auto',
    zIndex: -1, // Ensure the logo does not interfere with other clickable elements
  },

  navbar: {
    position: 'right',
    left: '1000px',
    right: '2px', // Adjust the right position as needed
    top: '2px', // Adjust the top position as needed
  },

  animationText: {
    animation: '$fadeInOut 5s ease-in-out infinite',
    textAlign: 'center',
    marginTop: '0px',
    marginLeft: '60px'
  },

  // Define keyframe animation for fadeInOut
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
});
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 72,
  },
  watermark: {
    position: 'absolute',
    bottom: 350,
    left: 150,
    width: 325,
    height: 150,
    opacity: 0.1,
    alignItems: 'center'
  },
  heading: {
    fontSize: 20,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  sectionColumn: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  column: {
    width: '50%',
    padding: 5,
  },
  title: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  content: {
    fontSize: 10,
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: "80%",
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "20%",
    fontSize: 11,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#ededed',
  },
  tableCol: {
    width: "20%",
    fontSize: 10,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
  },
});

const MyDocument = ({ reportDetails }) => {
  if (!reportDetails) {
    return <Document><Page size="A4"><Text>No report details provided.</Text></Page></Document>;
  }

  const rows = Array.isArray(reportDetails.rows) ? reportDetails.rows : [];
  const detailFields = Object.keys(reportDetails).filter(key => key !== 'rows' && reportDetails[key] !== undefined);

  const halfwayIndex = Math.ceil(detailFields.length / 2);
  const firstColumnFields = detailFields.slice(0, halfwayIndex);
  const secondColumnFields = detailFields.slice(halfwayIndex);

  return (
    <>
    
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.watermark} src={logo} />
        <Text style={styles.heading}>{reportDetails.reportNumber || 'No Report Number'}</Text>
        <View style={styles.sectionColumn}>
          <View style={styles.column}>
            {firstColumnFields.map(key => (
              <View key={key}>
                <Text style={styles.title}>{key.charAt(0).toUpperCase() + key.slice(1)} : {`${reportDetails[key]}`}</Text>
              </View>
            ))}
          </View>
          <View style={styles.column}>
            {secondColumnFields.map(key => (
              <View key={key}>
                <Text style={styles.title}>{key.charAt(0).toUpperCase() + key.slice(1)} : {`${reportDetails[key]}`}</Text>
              </View>
            ))}
          </View>
        </View>
        {rows.length > 0 && (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {Object.keys(rows[0]).map((col, index) => (
                <Text key={index} style={styles.tableColHeader}>{col.charAt(0).toUpperCase() + col.slice(1)}</Text>
              ))}
            </View>
            {rows.map((row, rowIndex) => (
              <View key={rowIndex} style={styles.tableRow}>
                {Object.entries(row).map(([key, cell], cellIndex) => (
                  <Text key={cellIndex} style={styles.tableCol}>{`${cell}`}</Text>
                ))}
              </View>
            ))}
          </View>
        )}
      </Page>
    </Document>
    </>
  );
};

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const fetchReports = async () => {
      const querySnapshot = await getDocs(collection(db, 'Reports'));
      const reportsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReports(reportsList);
    };
    fetchReports();
    document.body.style.backgroundColor = '#add8e6';
    document.body.style.color = 'black';

    // Cleanup function to remove styles when component unmounts
    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.color = '';
    };
  }, []);

  const generateReport = async (reportNumber) => {
    const reportRef = collection(db, 'Reports');
    const q = query(reportRef, where("reportNumber", "==", reportNumber));
    const querySnapshot = await getDocs(q);
    const reportDetails = querySnapshot.docs.map(doc => doc.data())[0];
    const blob = await pdf(<MyDocument reportDetails={reportDetails} />).toBlob();
    saveAs(blob, `Report-${reportNumber}.pdf`);
  };

  const filteredReports = searchTerm.length > 0 ? reports.filter(report =>
    (report.reportNumber?.toLowerCase() ?? "").includes(searchTerm.toLowerCase()) ||
    (report.technician?.toLowerCase() ?? "").includes(searchTerm.toLowerCase()) ||
    (report.purchaseOrder?.toLowerCase() ?? "").includes(searchTerm.toLowerCase())
  ) : reports;

  return (
    <>
    <TechnicianNavbar className={classes.navbar}/>
    <Container className="mt-4">
      <h1>Reports</h1>
      <Row className="mb-3">
        <Col>
          <Form.Control
            type="text"
            placeholder="Search reports by number, technician, or purchase order..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>
      <Row xs={1} md={2} lg={3} className="g-4">
        {filteredReports.length > 0 ? (
          filteredReports.map((report) => (
            <Col key={report.id}>
             <Card style={{ backgroundColor: '#add8e6', color: 'black' }}></Card>
              <Card>
                <Card.Header as="h6">Report Number: {report.reportNumber}</Card.Header>
                <Card.Body>
                  <Card.Title>Technician: {report.technician}</Card.Title>
                  <Card.Text>Purchase Order: {report.purchaseOrder}</Card.Text>
                  <Card.Text>Date: {report.date}</Card.Text>
                  <Button variant="primary" onClick={() => navigate(`/viewTest/${report.reportNumber}`)}>View</Button>
                  <Button style={{ marginLeft: "20px" }} variant="primary" onClick={() => generateReport(report.reportNumber)}>Download</Button>
                  <Button style={{ marginLeft: "20px" }} variant="primary" onClick={() => navigate(`/editTest/${report.reportNumber}`)}>Edit</Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>No reports found.</Col>
        )}
      </Row>
    </Container>
    </>
  );
};

export default Reports;
