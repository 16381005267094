
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, deleteDoc,getDoc,setDoc, updateDoc,addDoc, collection } from 'firebase/firestore';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';

const ViewPending = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();
  const [changedFields, setChangedFields] = useState(new Set());
  const [formValues, setFormValues] = useState({
    reportNumber: '',
    date: '',
    revision: '',
    page: '',
    purchaseOrder: '',
    contactPerson: '',
    remarks: '',
    technician: '',
    assistant: '',
    consumables: '',
    clientUse: '',
    certifyingAuthority: ''
  });
  const [rows, setRows] = useState([]);
useEffect(() => {
  const fetchReport = async () => {
    const docRef = doc(db, 'PendingRequests', reportId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const reportData = docSnap.data();
      // Set form values
      setFormValues({
        ...reportData,
        revision: reportData.revision || "1" // Set to "1" if revision is not available
      });
      setRows(reportData.rows || []); // If rows is null, set it to an empty array
    } else {
      console.log('No such document!');
    }
  };

  fetchReport();
}, [reportId]);


const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormValues({ ...formValues, [name]: value });
  setChangedFields(new Set(changedFields.add(name)));
};

const handleAddRow = () => {
  const newRow = { item: '', qty: '', heatNo: '', serialLoad: '', description: '', results: '' };
  setRows([...rows, newRow]);
};

const handleRowChange = (idx, field, value) => {
  const updatedRows = rows.map((row, ridx) => (ridx === idx ? { ...row, [field]: value } : row));
  setRows(updatedRows);
};

const handleRemoveRow = (idx) => {
  setRows(rows.filter((_, ridx) => ridx !== idx));
};

const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    let newRevision = parseInt(formValues.revision, 10);
    newRevision = isNaN(newRevision) ? 2 : newRevision + 1;

    const updatedFormValues = {
      ...formValues,
      revision: newRevision.toString() // Convert back to string for consistency
    };
    
    const changed_fields=Array.from(changedFields) 
    // Update values in draftReports doc
    const reportRef = doc(db, 'Reports', reportId);
    await updateDoc(reportRef, {
      ...updatedFormValues,
      rows
    });

    

    // Create a new doc with updated values in approvedRequests collection
    const approvedRequestsRef = collection(db, 'approvedRequests');
    const approvedRequestDocRef = doc(approvedRequestsRef, reportId);
    await setDoc(approvedRequestDocRef, {
      ...updatedFormValues,
      rows,
      changed_fields
    });

    // Delete the document in pending requests
    const pendingRequestRef = doc(db, 'PendingRequests', reportId);
    await deleteDoc(pendingRequestRef);

    alert('Report approved successfully!');
    navigate('/admin/Home'); // Redirect to home page after approval
  } catch (error) {
    console.error('Error approving report:', error);
    alert('Error approving report. Please try again.');
  }
};

const handleReject = async () => {
  try {
    // Create a new doc with updated values in RejectedRequests collection
    const changed_fields=Array.from(changedFields) 
    const rejectedRequestsRef = collection(db, 'RejectedRequests');
    const rejectedRequestDocRef = doc(rejectedRequestsRef, reportId);
    await setDoc(rejectedRequestDocRef, {
      ...formValues,
      rows,
      changed_fields
    });

    // Delete the document in pending requests
    const pendingRequestRef = doc(db, 'PendingRequests', reportId);
    await deleteDoc(pendingRequestRef);

    alert('Report rejected successfully!');
    navigate('/admin/Home'); // Redirect to home page after rejection
  } catch (error) {
    console.error('Error rejecting report:', error);
    alert('Error rejecting report. Please try again.');
  }
};


return (
  <Container style={{ alignItems: "flex-start" }} className="mt-5">
    <h2>Edit Test</h2>
    <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formReportNumber">
            <Form.Label>Report Number</Form.Label>
            <Form.Control
              type="text"
              name="reportNumber"
              value={formValues.reportNumber}
              onChange={handleInputChange}
              readOnly // Make it read-only
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={formValues.date}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col>
        <Form.Group controlId="formRevision">
  <Form.Label>Revision</Form.Label>
  <Form.Control
    type="text"
    name="revision"
    value={formValues.revision}
    onChange={handleInputChange}
    readOnly // Make the revision field read-only
  />
</Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formPage">
            <Form.Label>Page</Form.Label>
            <Form.Control
              type="text"
              name="page"
              value={formValues.page}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formPurchaseOrder">
            <Form.Label>Purchase Order</Form.Label>
            <Form.Control
              type="text"
              name="purchaseOrder"
              value={formValues.purchaseOrder}
              onChange={handleInputChange}
              placeholder="Enter purchase order"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formContactPerson">
            <Form.Label>Contact Person</Form.Label>
            <Form.Control
              type="text"
              name="contactPerson"
              value={formValues.contactPerson}
              onChange={handleInputChange}
              placeholder="Enter contact person"
            />
          </Form.Group>
        </Col>
      </Row>
      {/* Dynamic Rows Section */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Item</th>
            <th>Qty</th>
            <th>Heat#</th>
            <th>Serial/Load PTH#</th>
            <th>Description</th>
            <th>Results</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <tr key={idx}>
              <td><Form.Control type="text" value={row.item} onChange={(e) => handleRowChange(idx, 'item', e.target.value)} /></td>
              <td><Form.Control type="number" value={row.qty} onChange={(e) => handleRowChange(idx, 'qty', e.target.value)} /></td>
              <td><Form.Control type="text" value={row.heatNo} onChange={(e) => handleRowChange(idx, 'heatNo', e.target.value)} /></td>
              <td><Form.Control type="text" value={row.serialLoad} onChange={(e) => handleRowChange(idx, 'serialLoad', e.target.value)} /></td>
              <td><Form.Control type="text" value={row.description} onChange={(e) => handleRowChange(idx, 'description', e.target.value)} /></td>
              <td><Form.Control type="text" value={row.results} onChange={(e) => handleRowChange(idx, 'results', e.target.value)} /></td>
              <td><Button variant="danger" onClick={() => handleRemoveRow(idx)}>Remove</Button></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="primary" onClick={handleAddRow} className="mb-3">Add Test Entry</Button>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formRemarks">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="remarks"
              value={formValues.remarks}
              onChange={handleInputChange}
              placeholder="Enter remarks"
            />
          </Form.Group>
        </Col>
        </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formTechnician">
            <Form.Label>Technician</Form.Label>
            <Form.Control
              type="text"
              name="technician"
              value={formValues.technician}
              onChange={handleInputChange}
              placeholder="Technician name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formAssistant">
            <Form.Label>Assistant</Form.Label>
            <Form.Control
              type="text"
              name="assistant"
              value={formValues.assistant}
              onChange={handleInputChange}
              placeholder="Assistant name"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formConsumables">
            <Form.Label>Consumables</Form.Label>
            <Form.Control
              type="text"
              name="consumables"
              value={formValues.consumables}
              onChange={handleInputChange}
              placeholder="List consumables"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formClientUse">
            <Form.Label>Client Use</Form.Label>
            <Form.Control
              type="text"
              name="clientUse"
              value={formValues.clientUse}
              onChange={handleInputChange}
              placeholder="Specify if for client use"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formCertifyingAuthority">
            <Form.Label>Certifying Authority</Form.Label>
            <Form.Control
              type="text"
              name="certifyingAuthority"
              value={formValues.certifyingAuthority}
              onChange={handleInputChange}
              placeholder="Enter certifying authority"
            />
          </Form.Group>
        </Col>
      </Row>
      
      <Button style={{marginRight:"20px"}}variant="success" type="submit">Approve</Button>
      <Button variant="danger" onClick={handleReject}>Reject</Button>

    </Form>
  </Container>
);
};

export default ViewPending;

