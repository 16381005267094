import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; 
import { collection, getDocs } from 'firebase/firestore';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const PendingRequests = () => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPendingRequests = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'PendingRequests'));
        const pendingRequestsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPendingRequests(pendingRequestsList);
      } catch (error) {
        console.error('Error fetching pending requests:', error);
      }
    };

    fetchPendingRequests();
  }, []);

  return (
    <Container className="mt-4">
      <h1>Pending Requests</h1>
      <Row xs={1} md={2} lg={3} className="g-4">
        {pendingRequests.length > 0 ? (
          pendingRequests.map(request => (
            <Col key={request.id}>
              <Card>
                <Card.Header as="h6">Request ID: {request.id}</Card.Header>
                
                <Card.Body>
                  <Button variant="primary" onClick={() => navigate(`/admin/viewpending/${request.id}`)}>View & Approve</Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>No pending requests found.</Col>
        )}
      </Row>
    </Container>
  );
};

export default PendingRequests;
