// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import TechnicianHome from "./components/technician/Home";
import MPtest from "./components/technician/MPtesting";
import CladTHKTesting from "./components/technician/CladTHKTesting";
import PMIrptTesting from "./components/technician/PMIrptTesting";
import UTtest from "./components/technician/UTtesting";
import UTDtest from "./components/technician/UTDtesting";
import Reports from "./components/Reports";
import ViewTest from './components/viewTest';
import AdminHome from './components/admin/Home';
import ProtectedRoute from "./components/ProtectedRoute";
import EditTest from './components/editTest';
import PendingRequests from './components/admin/pendingRequest';
import ViewPending from './components/admin/viewPending';
import PTtest from "./components/technician/PTtesting";
import HTtest from "./components/technician/HTtesting";
import VTtest from "./components/technician/VTtesting";
import Certification from "./components/admin/Certification";
import Equipments from "./components/admin/Equipments";
import ClientHome from "./components/client/Home";
import ClientReports from "./components/client/ClientReports";
import JobSafetyAnalysis from "./components/technician/JobSafetyAnalysis";
import ComputedRadiographicInspectionTesting from "./components/technician/ComputedRadiographicInspectionTesting";
import DigitalRadiographicTesting from "./components/technician/DigitalRadiographicTesting";
import ConventionalRadiographicTesting from "./components/technician/ConventionalRadiographicTesting";
import { UserAuthContextProvider } from "./context/UserAuthContext";

document.body.style.backgroundColor = '#add8e6';
function App() {
  return (
    <UserAuthContextProvider>
      

              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                
                
                {/* Protected Routes */}
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute allowedRoles={['admin', 'technician']}>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/home"
                  element={
                    <ProtectedRoute allowedRoles={['technician','admin']}>
                      <TechnicianHome />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/MPtesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <MPtest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/UTtesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <UTtest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/UTDtesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <UTDtest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/PTtesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <PTtest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/HTtesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <HTtest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/VTtesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <VTtest />
                    </ProtectedRoute>
                  }
                />
                 <Route
                  path="/technician/CladTHKTesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <CladTHKTesting />
                    </ProtectedRoute>
                  }
                />
                 <Route
                  path="/technician/PMIrptTesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <PMIrptTesting />
                    </ProtectedRoute>
                  }
                />
                  <Route
                  path="/technician/ComputedRadiographicInspectionTesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <ComputedRadiographicInspectionTesting />
                    </ProtectedRoute>
                  }
                />
                  <Route
                  path="/technician/DigitalRadiographicTesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <DigitalRadiographicTesting />
                    </ProtectedRoute>
                  }
                />
                   <Route
                  path="/technician/ConventionalRadiographicTesting"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <ConventionalRadiographicTesting />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Reports"
                  element={
                    <ProtectedRoute allowedRoles={['admin', 'technician']}>
                      <Reports />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/viewTest/:reportId"
                  element={
                    <ProtectedRoute allowedRoles={['admin','technician', 'client']}>
                      <ViewTest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/editTest/:reportId"
                  element={
                    <ProtectedRoute allowedRoles={['admin','technician']}>
                      <EditTest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/technician/JobSafetyAnalysis"
                  element={
                    <ProtectedRoute allowedRoles={['technician']}>
                      <JobSafetyAnalysis />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/Home"
                  element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <AdminHome />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/pending"
                  element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <PendingRequests />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/viewpending/:reportId"
                  element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <ViewPending />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/Certification"
                  element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <Certification />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/Equipments"
                  element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <Equipments />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/client/Home/"
                  element={
                    <ProtectedRoute allowedRoles={['client']}>
                      <ClientHome />
                    </ProtectedRoute>
                                  }
                  />
                <Route
                  path="/client/Home/ClientReports"
                  element={
                    <ProtectedRoute allowedRoles={['client']}>
                      <ClientReports />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            
      
    </UserAuthContextProvider>
  );
}

export default App;
