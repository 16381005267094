import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Container, Row, Col, Table } from 'react-bootstrap';

const ViewTest = () => {
  const { reportId } = useParams();
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    const fetchReport = async () => {
      const docRef = doc(db, 'Reports', reportId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setReportData(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchReport();
    document.body.style.backgroundColor = '#add8e6';
    document.body.style.color = 'black';

    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.color = '';
    };
  }, [reportId]);

  if (!reportData) {
    return <Container>Loading...</Container>; 
  }

  
  const { rows, changed_fields, reportNumber, date, revision, contactPerson, customer, phone,location, fax,page,jobNumber,partDescription,procedure,...otherData } = reportData;

  return (
    <Container className="mt-4">
      <h2>View Report</h2>
      <Row>
        <Col sm={6} className="mb-3"><strong>Report Number:</strong> {reportNumber}</Col>
        <Col sm={6} className="mb-3"><strong>Date:</strong> {date}</Col>
        <Col sm={6} className="mb-3"><strong>Revision:</strong> {revision}</Col>
        <Col sm={6} className="mb-3"><strong>Contact Person:</strong> {contactPerson}</Col>
        <Col sm={6} className="mb-3"><strong>Customer:</strong> {customer}</Col>
        <Col sm={6} className="mb-3"><strong>Phone:</strong> {phone}</Col>
        <Col sm={6} className="mb-3"><strong>Location:</strong> {location}</Col>
        <Col sm={6} className="mb-3"><strong>Fax:</strong> {fax}</Col>
        <Col sm={6} className="mb-3"><strong>Page:</strong> {page}</Col>
        <Col sm={6} className="mb-3"><strong>Job Number:</strong> {jobNumber}</Col>
        <Col sm={6} className="mb-3"><strong>Part Description:</strong> {partDescription}</Col>
        <Col sm={6} className="mb-3"><strong>Procedure:</strong> {procedure}</Col>
      </Row>
      <Row>
        
        {Object.entries(otherData).map(([key, value], index) => (
          <Col sm={6} key={index} className="mb-3">
            <strong>{key}:</strong> {value.toString()}
          </Col>
        ))}
      </Row>
      
      {rows && (
        <>
          <h3>Rows</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                {rows.length > 0 && Object.keys(rows[0]).map((header, index) => <th key={index}>{header}</th>)}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, valueIndex) => <td key={valueIndex}>{value.toString()}</td>)}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  );
};

export default ViewTest;
