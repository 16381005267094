
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyBTPVHcjQ_nTQ7FX57H8LNnEwQVtmr2q-4",
  authDomain: "ecs-integrity-da580.firebaseapp.com",
  projectId: "ecs-integrity-da580",
  storageBucket: "ecs-integrity-da580.appspot.com",
  messagingSenderId: "73003317989",
  appId: "1:73003317989:web:7019c559a0ec8f2272f20c",
  measurementId: "G-LZKGFCHNF1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
export const auth = getAuth(app);
export default app;
export const db = getFirestore(app);




