import React, { useState, useEffect  } from 'react';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
import { doc, setDoc , collection, getDocs, query, where, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import Logo from '../../assets/logo.jpeg'; // Import the logo
import { Link, useNavigate} from 'react-router-dom';

const Certification = () => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    techName: '',
    visionExamExp: '',
    cwiExp: '',
    etCertExp: '',
    htCertExp: '',
    mtCertExp: '',
    ptCertExp: '',
    pautCertExp: '',
    rtCertExp: '',
    rtcrdrCertExp: '',
    uttCertExp: '',
    utswCertExp: '',
    vtCertExp: '',
    techCertLink: '',
    visualCertLink: '' ,
    pmiRptCertExp: '',
    cladThkCertExp: ''
  });

  const [certifications, setCertifications] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');  

  useEffect(() => {
    // Load existing certifications
    loadCertifications();
  }, []);

  const loadCertifications = async () => {
    const certificationsCollection = collection(db, 'Certifications');
    const snapshot = await getDocs(certificationsCollection);
    const certificationsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCertifications(certificationsData);
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };
  const handleInputChange = (event) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSaveDraft = async () => {
    if (!formValues.techName) {
      alert('Technician Name is required.');
      return;
    }

    try {
      const techCertificationRef = doc(db, 'draftCertifications', formValues.techName);
      await setDoc(techCertificationRef, {
        ...formValues,
      });
      console.log('Draft saved with Technician Name:', formValues.techName);
      alert('Draft saved successfully!');
    } catch (error) {
      console.error('Error saving draft:', error);
      alert('Error saving draft. Please try again.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formValues.techName) {
        alert('Technician Name is required.');
        return;
    }
  
      try {
        const techCertificationRef = doc(db, 'Certifications', formValues.techName);
        await setDoc(techCertificationRef, {
          ...formValues,
        });
        console.log('Certification saved with Technician Name:', formValues.techName);
          alert('Saved successfully!');
          setFormValues({
            techName: '',
            visionExamExp: '',
            cwiExp: '',
            etCertExp: '',
            htCertExp: '',
            mtCertExp: '',
            ptCertExp: '',
            pautCertExp: '',
            rtCertExp: '',
            rtcrdrCertExp: '',
            uttCertExp: '',
            utswCertExp: '',
            vtCertExp: '',
            techCertLink: '',
            visualCertLink: '',
            pmiRptCertExp: '',
            cladThkCertExp: ''
          });
          loadCertifications(); // Refresh certifications
      } catch (error) {
        console.error('Error saving certification:', error);
        alert('Error saving certification. Please try again.');
      }
  };
  const handleEdit = (certification) => {
    setFormValues(certification);
  };

  const handleDelete = async (certificationId) => {
    try {
      await deleteDoc(doc(db, 'Certifications', certificationId));
      console.log('Certification deleted:', certificationId);
      alert('Ceritifcation deleted successfully!');
      loadCertifications(); // Update the list of certifications
    } catch (error) {
      console.error('Error deleting certification:', error);
      alert('Error deleting certification. Please try again.');
    }
  };

  const filteredCertifications = certifications.filter(certificate =>
    certificate.techName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
    <style>
      {`
        body {
          background-color: #add8e6;
             }
        `}
        </style>
    <Container style={ {alignItems:"flex-start"}}className="mt-5">
      <br></br>
      <h2>Certification Maintanence</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
          <Form.Group controlId="formTechnicianName">
              <Form.Label>Technician Name</Form.Label>
              <Form.Control
                type="text"
                name="techName"
                value={formValues.techName}
                onChange={handleInputChange}
                placeholder="Technician Name is Required"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formVisionExamExp">
              <Form.Label>Vision Exam Exp.</Form.Label>
              <Form.Control
                type="text"
                name="visionExamExp"
                value={formValues.visionExamExp}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formCWIExp">
              <Form.Label>CWI Exp.</Form.Label>
              <Form.Control
                type="text"
                name="cwiExp"
                value={formValues.cwiExp}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formETCertExp">
              <Form.Label>ET Cert Exp.</Form.Label>
              <Form.Select
                name="etCertExp"
                value={formValues.etCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formHTCertExp">
              <Form.Label>HT Cert Exp.</Form.Label>
              <Form.Select
                name="htCertExp"
                value={formValues.htCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formMTCertExp">
              <Form.Label>MT Cert Exp.</Form.Label>
              <Form.Select
                name="mtCertExp"
                value={formValues.mtCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPTCertExp">
              <Form.Label>PT Cert Exp.</Form.Label>
              <Form.Select
                name="ptCertExp"
                value={formValues.ptCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPAUTCertExp">
              <Form.Label>PAUT Cert Exp.</Form.Label>
              <Form.Select
                name="pautCertExp"
                value={formValues.pautCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formRTCertExp">
              <Form.Label>RT Cert Exp.</Form.Label>
              <Form.Select
                name="rtCertExp"
                value={formValues.rtCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formRTCRDRCertExp">
              <Form.Label>RT CR/DR Cert Exp.</Form.Label>
              <Form.Select
                name="rtcrdrCertExp"
                value={formValues.rtcrdrCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formUTTCertExp">
              <Form.Label>UTT Cert Exp.</Form.Label>
              <Form.Select
                name="uttCertExp"
                value={formValues.uttCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formUTSWCertExp">
              <Form.Label>UT SW Cert Exp.</Form.Label>
              <Form.Select
                name="utswCertExp"
                value={formValues.utswCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formVTCertExp">
              <Form.Label>VT Cert Exp.</Form.Label>
              <Form.Select
                name="vtCertExp"
                value={formValues.vtCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formCladThkCertExp">
              <Form.Label>CladThkCertExp.</Form.Label>
              <Form.Select
                name="cladThkCertExp"
                value={formValues.cladThkCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPMIRPTCertExp">
              <Form.Label>PMI RPT Cert Exp.</Form.Label>
              <Form.Select
                name="pmiRptCertExp"
                value={formValues.pmiRptCertExp}
                onChange={handleInputChange}
                >
                  <option value="">Select an Option</option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Form.Select>
            </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="formTechCertLink">
              <Form.Label>Technician Certification Link</Form.Label>
              <Form.Control
                type="text"
                name="techCertLink"
                value={formValues.techCertLink}
                onChange={handleInputChange}
                placeholder="Add Certification link"
              />
            </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="formVisualCertLink">
              <Form.Label>Visual Certification Link</Form.Label>
              <Form.Control
                type="text"
                name="visualCertLink"
                value={formValues.visualCertLink}
                onChange={handleInputChange}
                placeholder="Add Visual Certification Link"
              />
            </Form.Group>
          </Col>
        </Row>
<img src={Logo} alt="Logo" style={{ position: 'absolute', top: '4.5px', left: '8px', width: '150px', height: 'auto', zIndex: 1 }}/>
  
            {/* <Button style={{marginRight:'20px',marginBottom:"20px"} } variant="secondary" onClick={handleSaveDraft}>Save Draft</Button> */}
            <Button style={{ marginBottom: "20px" }} type="submit" variant="success">Submit</Button>
      </Form>

      {/* Search input field */}
      <Form.Group controlId="formSearch">
          <Form.Control
            type="text"
            placeholder="Search by Technician Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginBottom: '10px',
              fontSize: '16px',
              boxSizing: 'border-box'
            }}
          />
        </Form.Group>
        {/* Table to display equipments */}
        <Table striped bordered hover>
          <thead> 
            <tr>
              <th>Technician Name</th>
              <th>Vision Exam Exp.</th>
              <th>CWI Exp.</th>
              <th>ET Cert Exp.</th>
              <th>HT Cert Exp.</th>
              <th>MT Cert Exp.</th>
              <th>PT Cert Exp.</th>
              <th>PAUT Cert Exp.</th>
              <th>RT Cert Exp.</th>
              <th>RT CR/DR Cert Exp.</th>
              <th>UTT Cert Exp.</th>
              <th>UT SW Cert Exp.</th>
              <th>VT Cert Exp.</th>
              <th>Clad Thk Cert Exp.</th>
              <th>PMI RPT Cert Exp.</th>
              <th>Tech Cert Link</th>
              <th>Visual Cert Link</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCertifications.map(certification => (
              <tr key={certification.id}>
                <td>{certification.techName}</td>
                <td>{certification.visionExamExp}</td>
                <td>{certification.cwiExp}</td>
                <td>{certification.etCertExp}</td>
                <td>{certification.htCertExp}</td>
                <td>{certification.mtCertExp}</td>
                <td>{certification.ptCertExp}</td>
                <td>{certification.pautCertExp}</td>
                <td>{certification.rtCertExp}</td>
                <td>{certification.rtcrdrCertExp}</td>
                <td>{certification.uttCertExp}</td>
                <td>{certification.utswCertExp}</td>
                <td>{certification.vtCertExp}</td>
                <td>{certification.cladThkCertExp}</td>
                <td>{certification.pmiRptCertExp}</td>
                <td>{certification.techCertLink}</td>
                <td>{certification.visualCertLink}</td>
                <td>
                  <Button variant="primary" onClick={() => handleEdit(certification)}>Edit</Button>
                  <Button variant="danger" onClick={() => handleDelete(certification.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
    </Container>
    </>
  );
};

export default Certification;
