import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust the path as necessary
import { collection, getDocs, query as firestoreQuery, where } from 'firebase/firestore';
import { Card, Button, Container, Row, Col, Form, Dropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const ClientReports = () => {
  const [reports, setReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        let reportsQuery = collection(db, 'Reports');

        // Apply search filter if searchTerm or selectedFilters exist
        if (searchTerm || selectedFilters.length > 0) {
          // Construct the query based on selected filters
          let filters = [];

          if (searchTerm && selectedFilters.length > 0) {
            const trimmedSearchTerm = searchTerm.trim();
            const dynamicFilters = selectedFilters.map(filter => {
              switch (filter) {
                case 'Purchase Order':
                  return [
                    where('purchaseOrder', '>=', trimmedSearchTerm),
                    where('purchaseOrder', '<=', trimmedSearchTerm + '\uf8ff')
                  ];
                case 'Work Order Number':
                  return [
                    where('workOrderNumber', '>=', trimmedSearchTerm),
                    where('workOrderNumber', '<=', trimmedSearchTerm + '\uf8ff')
                  ];
                case 'Job Number':
                  return [
                    where('jobNumber', '>=', trimmedSearchTerm),
                    where('jobNumber', '<=', trimmedSearchTerm + '\uf8ff')
                  ];
                // Add more cases for other selected filters...
                default:
                  return [];
              }
            });
            
            // Flatten the array of filters
            filters.push(...dynamicFilters.flat());
          }

          if (selectedFilters.includes('Work Order Number')) {
            filters.push(where('workOrderNumber', '>=', searchTerm.trim()), where('workOrderNumber', '<=', searchTerm.trim() + '\uf8ff'));
          }

          if (selectedFilters.includes('Job Number')) {
            filters.push(where('jobNumber', '>=', searchTerm.trim()), where('jobNumber', '<=', searchTerm.trim() + '\uf8ff'));
          }

          if (selectedFilters.includes('Skid ID')) {
            filters.push(where('skidId', '>=', searchTerm.trim()), where('skidId', '<=', searchTerm.trim() + '\uf8ff'));
          }

          if (selectedFilters.includes('Method of Testing')) {
            filters.push(where('methodOfTesting', '>=', searchTerm.trim()), where('methodOfTesting', '<=', searchTerm.trim() + '\uf8ff'));
          }

          if (selectedFilters.includes('Spool ID')) {
            filters.push(where('spoolId', '>=', searchTerm.trim()), where('spoolId', '<=', searchTerm.trim() + '\uf8ff'));
          }

          reportsQuery = firestoreQuery(reportsQuery, ...filters);
        }
        
        const querySnapshot = await getDocs(reportsQuery);
        const reportsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setReports(reportsList);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchReports();

    // Cleanup function to remove styles when component unmounts
    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.color = '';
    };
  }, [searchTerm, selectedFilters]); // Add searchTerm and selectedFilters to the dependency array

  const handleFilterSelect = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter(f => f !== filter)); // Deselect if already selected
    } else {
      setSelectedFilters([...selectedFilters, filter]); // Select if not selected
    }
  };

  return (
    <Container style={{ display: "block"}} className="mt-4 ">
      <h1>Client Reports</h1>
      <Row className="mb-3">
        <Col>
          <Form.Control 
            type="text"
            placeholder="Search reports by number, technician, or purchase order..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: '110%' }}
          />
        </Col>
        <Col xs="auto" className="ml-auto">
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-filter-by">
              Filter By
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleFilterSelect('Purchase Order')}
                active={selectedFilters.includes('Purchase Order')}
              >
                Purchase Order
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFilterSelect('Work Order Number')}
                active={selectedFilters.includes('Work Order Number')}
              >
                Work Order Number
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFilterSelect('Job Number')}
                active={selectedFilters.includes('Job Number')}
              >
                Job Number
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFilterSelect('Skid ID')}
                active={selectedFilters.includes('Skid ID')}
              >
                Skid ID
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFilterSelect('Method of Testing')}
                active={selectedFilters.includes('Method of Testing')}
              >
                Method of Testing
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFilterSelect('Spool ID')}
                active={selectedFilters.includes('Spool ID')}
              >
                Spool ID
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={3} className="g-4">
        {reports.length > 0 ? (
          reports.map(report => (
            <Col key={report.id}>
              <Card style={{ backgroundColor: '#add8e6', color: 'black' }}>
                <Card.Header as="h6">Report Number: {report.reportNumber}</Card.Header>
                <Card.Body>
                  <Card.Title>Technician: {report.technician}</Card.Title>
                  <Card.Text>Purchase Order: {report.purchaseOrder}</Card.Text>
                  <Card.Text>Date: {report.date}</Card.Text>
                 <Button variant="primary" style={{ backgroundColor: 'navy' }} onClick={() => navigate(`../../viewTest/${report.reportNumber}`)}>View</Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>No reports found.</Col>
        )}
      </Row>
    </Container>
  );
};

export default ClientReports;
