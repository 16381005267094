import React from 'react';
import TechnicianNavbar from '../technician/Navigation/TechnicianNavbar';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportsIcon from '../../assets/reports.png'; // Ensure this path is correct
import Logo from '../../assets/logo.jpeg'; // Import the logo


// Custom makeStyles hook for the component styles
const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    marginTop: '100px',
    borderRadius: 5,
    boxShadow: '0 8px 18px rgba(0, 0, 0, 0.4)',
    transition: 'transform 0.3s ease-in-out',
    marginLeft: '2px',
    '&:hover': {
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
      transform: 'scale(1.05)',
    },
  },
  media: {
    height: 140,
  },
  logo: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.5,
    width: '300px', // Control the size of the logo
    height: 'auto',
    zIndex: -1, // Ensure the logo does not interfere with other clickable elements
  },

  navbar: {
    position: 'right',
    left: '1000px',
    right: '2px', // Adjust the right position as needed
    top: '2px', // Adjust the top position as needed
  },

  animationText: {
    animation: '$fadeInOut 5s ease-in-out infinite',
    textAlign: 'center',
    marginTop: '0px',
    marginLeft: '60px'
  },

  // Define keyframe animation for fadeInOut
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
});

const Home = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const classes = useStyles();


  const handleCardClick = () => {
    navigate('/Reports');
  };

  return (
    <>
      <style>
        {`
        body {
          background-color: #add8e6;
          color: black;
        }
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        `}
      </style>
     

      
      {/* Logo with Opacity */}
      {/* Logo positioned on top of the background */}
      {/* <img src={Logo} alt="Logo" style={{ position: 'absolute', top: '0px', left: '0px', width: '165px', height: 'auto', zIndex: 1 }} />*/}
      
      <TechnicianNavbar className={classes.navbar}/>
     
      <div style = {{marginTop:'50px'}} className="text-center">
        <h3> Welcome to Technician Dashboard</h3>
        Logged In as :
        {user && user.email}
      </div>

      

      <div style = {{marginTop:'100px'}} className="container">
        <div className={classes.animationText}>
          <h5>Driving Efficiency and Value through Innovative Design and Engineering.<br></br><br></br> Forging the way to a new inspection standard.</h5>
        </div>
      </div>

    </>
  );
};

export default Home;
